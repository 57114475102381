import React, { useContext } from 'react';

import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AddIcon from '@mui/icons-material/Add';
import { useAuth0 } from '@auth0/auth0-react';

import { Paths } from '@/types/paths';
import { UserProfileContext } from '@/contexts/UserProfileContext';

interface Props {
  title: string;
}

const TabPageHeader = ({ title }: Props) => {
  const { user } = useContext(UserProfileContext);
  const { push, pathname } = useRouter();
  const { loginWithRedirect } = useAuth0();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const goToAddCompany = async () => {
    if (user) {
      return await push({ pathname: Paths.NewCompany });
    }

    return await loginWithRedirect({
      appState: { returnTo: Paths.NewCompany }
    });
  };
  const goToTechSolution = async () => {
    if (user) {
      return await push({ pathname: Paths.SearchRequest });
    }

    return await loginWithRedirect({
      appState: { returnTo: Paths.SearchRequest }
    });
  };

  return (
    <Stack
      direction={mobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: mobile ? 2 : 0 }}
    >
      <Typography variant="h3" sx={{ my: 2 }}>
        Search {title.toLowerCase()}
      </Typography>

      {pathname === Paths.Companies && (
        <Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            onClick={goToAddCompany}
            sx={{ py: 0.6, px: 0.9, mr: mobile ? 1 : 1 }}
          >
            Add company
          </Button>

          <Button
            variant="outlined"
            startIcon={<PrecisionManufacturingIcon />}
            size="small"
            onClick={goToTechSolution}
            sx={{ py: 0.6, px: 0.9 }}
          >
            Need a tech solution?
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default TabPageHeader;
