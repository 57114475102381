import { useRouter } from 'next/router';
import { Card, Tab, Tabs } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import { Paths } from '@/types/paths';
import InvestorsTab from '@/content/InvestorsTab';
import FundingRounds from '@/content/FundingRounds';
import CompaniesTab from '@/content/CompaniesTab';
import Acquisitions from '@/content/Acquisitions';
import { TabsContainerWrapper } from '@/components/Wrappers/TabsContainerWrapper';

const TabPage = () => {
  const { pathname, push } = useRouter();

  const handleChange = async (
    _event: React.SyntheticEvent,
    newValue: string
  ) => {
    await push(newValue);
  };

  if (!pathname) {
    return null;
  }

  return (
    <>
      {pathname && (
        <TabContext value={pathname}>
          <TabsContainerWrapper>
            <Tabs
              value={pathname}
              onChange={handleChange}
              aria-label="Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Companies" value={Paths.Companies} />
              {/*<Tab label="Investors" value={Paths.Investors} />*/}
              <Tab label="Funding Rounds" value={Paths.FundingRounds} />
              <Tab label="Acquisitions" value={Paths.Acquisitions} />
            </Tabs>
          </TabsContainerWrapper>

          <TabPanel value={Paths.Companies} style={{ padding: 0 }}>
            <Card sx={{ p: 2 }}>
              <CompaniesTab />
            </Card>
          </TabPanel>

          {/*<TabPanel value={Paths.Investors} style={{ padding: 0 }}>*/}
          {/*  <Card sx={{ p: 2 }}>*/}
          {/*    <InvestorsTab />*/}
          {/*  </Card>*/}
          {/*</TabPanel>*/}

          <TabPanel value={Paths.FundingRounds} style={{ padding: 0 }}>
            <Card sx={{ p: 2 }}>
              <FundingRounds />
            </Card>
          </TabPanel>

          <TabPanel value={Paths.Acquisitions} style={{ padding: 0 }}>
            <Card sx={{ p: 2 }}>
              <Acquisitions />
            </Card>
          </TabPanel>
        </TabContext>
      )}
    </>
  );
};

export default TabPage;
