import styled from 'styled-components';

export const CustomLink = styled.a`
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;

  & > *:not(img) {
    padding: 16px;
  }

  img {
    margin-left: 16px;
  }
`;