import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useRouterParams } from '@/hooks/useRouterParams';
import { OptionsProps } from '@/components/Filters/types';
import { useFiltersHelpers } from '@/components/Filters/hooks';
import ActionButtons from '@/components/Filters/ActionButtons';

const WebVersion = ({ label, filterId, filterName, data }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [options, setOptions] = useState<OptionsProps[]>(data);
  const [countCheckedOptions, setCountCheckedOptions] = useState<number>(0);

  const { clearParams } = useRouterParams();
  const { saveFilterParamsToUrl, addCheckedToOptions, checkOption } =
    useFiltersHelpers();

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const computeCountCheckedOptions = useCallback(() => {
    const count = options.reduce((acc, currentValue) => {
      return currentValue.checked ? acc + 1 : acc;
    }, 0);

    setCountCheckedOptions(count);
  }, [options]);

  useEffect(() => {
    computeCountCheckedOptions();
  }, [computeCountCheckedOptions]);

  useEffect(() => {
    setOptions(addCheckedToOptions(data, filterName));
  }, [addCheckedToOptions, filterName, data]);

  return (
    <>
      <Button
        data-testid="select-filter-button"
        aria-controls={!!anchorEl ? filterId : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleFilterButtonClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          {!!countCheckedOptions && (
            <Box
              sx={{
                color: '#333',
                background: '#2233541a',
                width: 20,
                height: 20,
                borderRadius: 10
              }}
            >
              {countCheckedOptions}
            </Box>
          )}

          <Typography variant="h5">{label}</Typography>
        </Stack>
      </Button>

      <Popover
        id={filterId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2}>
          <Box sx={{ width: 600, height: 400, overflowY: 'scroll' }} m={1}>
            <Grid container spacing={1} columns={{ xs: 4 }}>
              {options.map((option, index) => (
                <Grid item xs={2} key={index}>
                  <FormGroup key={option.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.checked}
                          data-testid={`${option.name}_checkbox`}
                        />
                      }
                      onChange={() =>
                        setOptions(checkOption(options, option.id))
                      }
                      label={option.name}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

          <ActionButtons
            onClear={() => {
              clearParams(filterName);
              handleFilterClose();
            }}
            onSave={() => {
              saveFilterParamsToUrl(options, filterName);
              handleFilterClose();
              computeCountCheckedOptions();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default WebVersion;
