import { useEffect, useState, useMemo } from 'react';

import {
  Accordion,
  Chip,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useRouterParams } from '@/hooks/useRouterParams';
import { OptionsProps } from '@/components/Filters/types';
import { useFiltersHelpers } from '@/components/Filters/hooks';
import ActionButtons from '@/components/Filters/ActionButtons';

const MobileVersion = ({ label, filterName, data }) => {
  const [options, setOptions] = useState<OptionsProps[]>(data);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { clearParams } = useRouterParams();
  const { saveFilterParamsToUrl, addCheckedToOptions, checkOption } =
    useFiltersHelpers();

  const countCheckedOptions = useMemo(
    () =>
      options.reduce((acc, currentValue) => {
        return currentValue.checked ? acc + 1 : acc;
      }, 0),
    [options]
  );

  useEffect(() => {
    setOptions(addCheckedToOptions(data, filterName));
  }, [addCheckedToOptions, data, filterName]);

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={(_event, expanded) => {
          setIsExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0, my: 0 }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {!!countCheckedOptions && (
              <Chip size="small" label={countCheckedOptions} />
            )}

            <Typography variant="h4">{label}</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ height: 400, overflowY: 'scroll' }}>
            <Grid container spacing={1} columns={{ xs: 2 }}>
              {options.map((option) => (
                <Grid item xs={2} key={`${option.id}`}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.checked || false}
                          data-testid={`${option.name}_checkbox`}
                        />
                      }
                      onChange={() => {
                        setOptions(checkOption(options, option.id));
                      }}
                      label={option.name}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

          <ActionButtons
            onClear={() => {
              clearParams(filterName);
              setIsExpanded(false);
            }}
            onSave={() => {
              saveFilterParamsToUrl(options, filterName);
              setIsExpanded(false);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MobileVersion;
