import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { FundingRound } from '@/models/FundingRound';
import UpsellBox from '@/content/CompaniesTab/Table/UpsellBox';
import RowBlurred from './RowBlurred';
import Row from './Row';

const TableLoader = () => {
  return (
    <TableRow>
      <TableCell sx={{ height: '58px', padding: 0 }}>
        <Skeleton
          animation="wave"
          sx={{
            borderRadius: 0,
            width: '100%',
            height: '100%',
            transform: 'none',
            background: 'none'
          }}
        />
      </TableCell>
    </TableRow>
  );
};

interface Props {
  fundingRounds: FundingRound[];
  totalResults: number;
}

const TableFundingRounds = ({ fundingRounds, totalResults }: Props) => {
  const { isAuthenticated } = useAuth0();

  if (!fundingRounds.length) {
    return (
      <Typography
        sx={{ py: 10 }}
        variant="h3"
        fontWeight="normal"
        color="text.secondary"
        align="center"
      >
        We couldn't find any results matching your search criteria
      </Typography>
    );
  }

  return (
    <TableContainer id="tableFundingRounds">
      <Table>
        <TableHead>
          {fundingRounds.length === 0 ? (
            <TableLoader />
          ) : (
            <TableRow>
              <TableCell
                align="left"
                width="24%"
                sx={{
                  backgroundColor: '#FBFBFC',
                  position: 'sticky',
                  left: 0
                }}
              >
                Transaction Name
              </TableCell>

              <TableCell align="left" width="15%">
                Company Name
              </TableCell>

              <TableCell align="left" width="15%">
                Funding Type
              </TableCell>

              <TableCell align="left" width="9%">
                Money Raised
              </TableCell>

              <TableCell align="left" width="9%">
                Announced Date
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {isAuthenticated ? (
            fundingRounds.map((fundingRound) => (
              <Row key={fundingRound.id} dataFundingRound={fundingRound} />
            ))
          ) : (
            <>
              {fundingRounds.slice(0, 5).map((fundingRound) => (
                <Row key={fundingRound.id} dataFundingRound={fundingRound} />
              ))}

              {fundingRounds.length > 5 &&
                fundingRounds
                  .slice(5, 8)
                  .map((fundingRound) => (
                    <RowBlurred
                      key={fundingRound.id}
                      dataFundingRound={fundingRound}
                    />
                  ))}
            </>
          )}
        </TableBody>
      </Table>

      {!isAuthenticated && totalResults > 7 && (
        <UpsellBox resultsCount={totalResults} />
      )}
    </TableContainer>
  );
};

export default TableFundingRounds;
