import styled from 'styled-components';
import { TableCell, Typography } from '@mui/material';

import { FundingRound } from 'src/models/FundingRound';
import { convertToInternationalCurrencySystem } from '@/utils/convertToInternationalCurrencySystem';

const CustomLink = styled.a`
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;

  & > *:not(img) {
    padding: 16px;
  }

  img {
    margin-left: 16px;
  }
`;

interface Props {
  fundingRound: FundingRound;
}

const ColumnsBlurred = ({ fundingRound }: Props) => {
  return (
    <>
      <TableCell
        className="organization_name"
        onClick={() => {}}
        sx={{ p: 1, padding: 0, filter: 'blur(5px)', borderWidth: 0 }}
      >
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {fundingRound.attributes?.transaction_name || '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell
        onClick={() => {}}
        sx={{
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }}
      >
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {fundingRound.attributes?.organization_name || '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell
        onClick={() => {}}
        sx={{
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }}
      >
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {fundingRound.attributes?.funding_type || '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell
        onClick={() => {}}
        sx={{
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }}
      >
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {fundingRound.attributes?.money_raised_usd
              ? convertToInternationalCurrencySystem(
                  fundingRound.attributes.money_raised_usd
                )
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell
        onClick={() => {}}
        sx={{
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }}
      >
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {fundingRound.attributes?.announced_date || '-'}
          </Typography>
        </CustomLink>
      </TableCell>
    </>
  );
};

export default ColumnsBlurred;
