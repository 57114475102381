import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import UpsellBox from '@/content/CompaniesTab/Table/UpsellBox';
import RowBlurred from './RowBlurred';
import Row from './Row';

// const TableLoader = () => {
//   return (
//     <TableRow>
//       <TableCell sx={{ height: '58px', padding: 0 }}>
//         <Skeleton
//           animation="wave"
//           sx={{
//             borderRadius: 0,
//             width: '100%',
//             height: '100%',
//             transform: 'none',
//             background: 'none'
//           }}
//         />
//       </TableCell>
//     </TableRow>
//   );
// };

const TableCompanies = ({ companies, totalResults }) => {
  const { isAuthenticated } = useAuth0();

  if (!companies.length) {
    return (
      <Typography
        sx={{ py: 10 }}
        variant="h3"
        fontWeight="normal"
        color="text.secondary"
        align="center"
      >
        We couldn't find any results matching your search criteria
      </Typography>
    );
  }

  return (
    <TableContainer id="tableCompanies">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" width="5%">
              Company
            </TableCell>

            <TableCell
              align="left"
              width="8%"
              sx={{
                backgroundColor: '#FBFBFC',
                position: 'sticky',
                left: 0
              }}
            >
              Name
            </TableCell>

            <TableCell align="left" width="19%">
              Description
            </TableCell>
            <TableCell align="left" width="15%">
              Primary Category
            </TableCell>
            <TableCell align="left" width="9%">
              Headquarters
            </TableCell>
            <TableCell align="left" width="10%">
              Revenue
            </TableCell>
            <TableCell align="left" width="5%">
              Employees
            </TableCell>
            <TableCell align="left" width="5%">
              Funding
            </TableCell>
            <TableCell align="left" width="10%">
              Industries
            </TableCell>
            <TableCell align="left" width="14%">
              Secondary Categories
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isAuthenticated ? (
            companies.map((company) => (
              <Row key={company.id} dataCompany={company} />
            ))
          ) : (
            <>
              {companies.slice(0, 5).map((company) => (
                <Row key={company.id} dataCompany={company} />
              ))}

              {companies.length > 5 &&
                companies
                  .slice(5, 8)
                  .map((company) => (
                    <RowBlurred key={company.id} dataCompany={company} />
                  ))}
            </>
          )}
        </TableBody>
      </Table>

      {!isAuthenticated && totalResults > 7 && (
        <UpsellBox resultsCount={totalResults} />
      )}
    </TableContainer>
  );
};

export default TableCompanies;
