import { TableRow } from '@mui/material';

import { Company } from '@/models/company';
import Columns from './Columns';

interface Props {
  dataCompany?: Company;
}

const Row = ({ dataCompany }: Props) => {
  return (
    <TableRow
      key={dataCompany.id}
      sx={{
        cursor: 'pointer',
        backgroundColor: 'white',
        '&>.organization_name': {
          backgroundColor: 'white'
        },
        '&:hover': {
          backgroundColor: '#FBFBFC',
          '&>.organization_name': {
            backgroundColor: '#FBFBFC'
          }
        }
      }}
    >
      <Columns dataCompany={dataCompany} />
    </TableRow>
  );
};

Row.defaultProps = {
  dataCompany: {} as Company
};

export default Row;
