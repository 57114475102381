import React from 'react';

import {
  Dialog,
  Button,
  Typography,
  Box,
  IconButton,
  Stack
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';

import SelectFilter from '@/components/Filters/SelectFilter';
import RangeFilter from '@/components/Filters/RangeFilter';
import AutocompleteFilter from '@/components/Filters/AutocompleteFilter';

interface Props {
  data: any;
  showModal: boolean;
  onHide: () => void;
  countActiveFilters: number;
}

const FiltersSidemodal = ({
  data,
  showModal,
  onHide,
  countActiveFilters
}: Props) => {
  const convertFilterObjectToArray = (filterObject) => {
    let filterArray = [];

    for (const option in filterObject) {
      filterArray.push({
        id: option,
        name: option,
        occurrences: filterObject[option]
      });
    }

    return filterArray;
  };

  return (
    <Dialog fullScreen open={showModal} onClose={onHide}>
      <Box
        p={2}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <Typography variant="h3">Filters</Typography>

          <IconButton onClick={onHide}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <AutocompleteFilter
          filterId="industries-filter"
          filterName="q[industries_matches_any][]"
          label="Industries"
          data={data.industries}
          sx={{ mr: 1 }}
        />

        <AutocompleteFilter
          filterId="categories-filter"
          filterName="q[categories_matches_any][]"
          label="Categories"
          data={data.categories}
          sx={{ mr: 1 }}
        />

        <AutocompleteFilter
          filterId="tags-filter"
          filterName="q[tags_matches_any][]"
          label="Tags"
          data={data.tags}
          sx={{ mr: 1 }}
        />

        <RangeFilter
          filterId="total-funding-type-filter"
          filterNameStart="q[total_funding_amount_usd_gt]"
          filterNameEnd="q[total_funding_amount_usd_lt]"
          label="Total Funding"
          sx={{ mr: 1 }}
        />

        <SelectFilter
          filterId="estimated-revenue-range-filter"
          filterName="q[estimated_revenue_range_matches_any][]"
          label="Estimated Revenue"
          data={convertFilterObjectToArray(data.estimated_revenue_range)}
          sx={{ mr: 1 }}
        />

        <SelectFilter
          filterId="employees-number-filter"
          filterName="q[nr_of_employees_matches_any][]"
          label="Employees"
          data={convertFilterObjectToArray(data.nr_of_employees)}
          sx={{ mr: 1 }}
        />

        <SelectFilter
          filterId="last-funding-type-filter"
          filterName="q[last_funding_type_matches_any][]"
          label="Last Funding Type"
          data={convertFilterObjectToArray(data.last_funding_type)}
          sx={{ mr: 1 }}
        />

        <AutocompleteFilter
          filterId="hq-country-filter"
          filterName="q[hq_country_cont_any][]"
          label="Location"
          data={convertFilterObjectToArray(data.hq_country)}
          sx={{ mr: 1 }}
        />

        <Button onClick={onHide} variant="contained" sx={{ marginTop: 'auto' }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {!!countActiveFilters && (
              <Box
                sx={{
                  color: '#333',
                  background: 'white',
                  width: 23,
                  height: 23,
                  borderRadius: 8
                }}
              >
                {countActiveFilters}
              </Box>
            )}

            <Typography variant="h5">Apply filters</Typography>
            <TuneIcon fontSize="small" sx={{ ml: 1 }} />
          </Stack>
        </Button>
      </Box>
    </Dialog>
  );
};

export default FiltersSidemodal;
