import { useQuery } from 'react-query';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { useApi } from '@/hooks/useApi';
import FundingRoundsTable from '@/content/FundingRounds/Table';
import ActiveFilters from '@/components/Filters/ActiveFilters';
import { fundingRoundsFiltersMapping } from './constants';
import Filters from './Filters';

const FundingRounds = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getFundingRoundsFilters } = useApi();
  const { data } = useQuery('getFundingRoundsFilters', getFundingRoundsFilters);

  return (
    <Box>
      <Filters />

      {!mobile && (
        <ActiveFilters
          filtersOptions={data}
          filtersMapping={fundingRoundsFiltersMapping}
        />
      )}

      <FundingRoundsTable />
    </Box>
  );
};

export default FundingRounds;
