import { Box, useTheme, useMediaQuery } from '@mui/material';

import WebVersion from './WebVersion';
import MobileVersion from './MobileVersion';

const options = [
  { label: '$1 Million', value: '1000000' },
  { label: '$10 Million', value: '10000000' },
  { label: '$25 Million', value: '25000000' },
  { label: '$50 Million', value: '50000000' },
  { label: '$75 Million', value: '75000000' },
  { label: '$100 Million', value: '100000000' },
  { label: '$250 Million', value: '250000000' },
  { label: '$500 Million', value: '500000000' },
  { label: '$1 Billion', value: '1000000000' },
  { label: '$2 Billion', value: '2000000000' }
];

interface Props {
  filterId: string;
  filterNameStart: string;
  filterNameEnd: string;
  label: string;
  sx?: object;
}

const RangeFilter = ({
  filterId,
  filterNameStart,
  filterNameEnd,
  label,
  sx
}: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ ...sx }}>
      {mobile ? (
        <MobileVersion
          filterNameStart={filterNameStart}
          filterNameEnd={filterNameEnd}
          label={label}
          options={options}
        />
      ) : (
        <WebVersion
          filterId={filterId}
          filterNameStart={filterNameStart}
          filterNameEnd={filterNameEnd}
          label={label}
          options={options}
        />
      )}
    </Box>
  );
};

export default RangeFilter;
