import React from 'react';

import { Acquisition } from 'src/models/Acquisition';
import AcquisitionTypeCell from './Cells/AcquisitionTypeCell';
import AcquisitionTransactionNameCell from './Cells/AcquisitionTransactionNameCell';
import AcquisitionTermsCell from './Cells/AcquisitionTermsCell';
import AcquisitionPriceUSDCell from './Cells/AcquisitionPriceUSDCell';
import AcquisitionAnnouncedDateCell from './Cells/AcquisitionAnnouncedDateCell';
import AcquisitionAcquirerNameCell from './Cells/AcquisitionAcquirerNameCell';
import AcquisitionAcquireeNameCell from './Cells/AcquisitionAcquireeNameCell';

interface Props {
  acquisition: Acquisition;
  isRowBlurred: boolean;
}

const Columns = ({ acquisition, isRowBlurred }: Props) => {
  return (
    <>
      {AcquisitionTransactionNameCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionAcquireeNameCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionAcquirerNameCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionAnnouncedDateCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionPriceUSDCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionTypeCell.GetCellBody(acquisition, isRowBlurred)}
      {AcquisitionTermsCell.GetCellBody(acquisition, isRowBlurred)}
    </>
  );
};

export default Columns;
