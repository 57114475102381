import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Divider,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useRouterParams } from '@/hooks/useRouterParams';
import ActionButtons from '@/components/Filters/ActionButtons';
import { CurrencyInputField } from '@/components/CurrencyInputField';

interface Props {
  filterNameStart: string;
  filterNameEnd: string;
  label: string;
  sx?: object;
  options: any;
}

const MobileVersion = ({
  filterNameStart,
  filterNameEnd,
  label,
  options
}: Props) => {
  const [minQuery, setMinQuery] = useState<string | undefined>(undefined);
  const [maxQuery, setMaxQuery] = useState<string | undefined>(undefined);
  const [countCheckedOptions, setCountCheckedOptions] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { hasParam, getParamValue, setMultipleParams, clearParams } =
    useRouterParams();

  const saveFiltersParamsToUrl = () => {
    let filters = [];

    if (minQuery) {
      filters.push({
        name: filterNameStart,
        value: minQuery
      });
    }

    if (maxQuery) {
      filters.push({
        name: filterNameEnd,
        value: maxQuery
      });
    }

    if (filters.length) {
      setMultipleParams(filters);
    }

    setIsExpanded(false);
  };

  const resetQueryInputs = () => {
    setMinQuery(undefined);
    setMaxQuery(undefined);
  };

  const setDefaultRanges = useCallback(() => {
    if (hasParam(filterNameStart)) {
      const minValueFromUrl = getParamValue(filterNameStart);

      if (minValueFromUrl && typeof minValueFromUrl === 'string') {
        setMinQuery(minValueFromUrl);
      }
    }

    if (hasParam(filterNameEnd)) {
      const maxValueFromUrl = getParamValue(filterNameEnd);

      if (maxValueFromUrl && typeof maxValueFromUrl === 'string') {
        setMaxQuery(maxValueFromUrl);
      }
    }
  }, [filterNameStart, filterNameEnd, getParamValue, hasParam]);

  const computeCountCheckedOptions = useCallback(() => {
    setCountCheckedOptions(0);

    if (minQuery) {
      setCountCheckedOptions((countCheckedOptions) => countCheckedOptions + 1);
    }

    if (maxQuery) {
      setCountCheckedOptions((countCheckedOptions) => countCheckedOptions + 1);
    }
  }, [maxQuery, minQuery]);

  useEffect(() => {
    computeCountCheckedOptions();
  }, [computeCountCheckedOptions]);

  useEffect(() => {
    resetQueryInputs();
    setDefaultRanges();
  }, [setDefaultRanges]);

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={(_event, expanded) => {
          setIsExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {!!countCheckedOptions && (
              <Chip size="small" label={countCheckedOptions} />
            )}

            <Typography variant="h4">{label}</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ height: 450, overflowY: 'scroll' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <CurrencyInputField
                  value={minQuery}
                  onChange={(event) => setMinQuery(event.target.value)}
                  placeholder="$ min"
                  size="small"
                  sx={{ width: '130px' }}
                />
              </Box>
              —
              <Box>
                <CurrencyInputField
                  value={maxQuery}
                  onChange={(event) => setMaxQuery(event.target.value)}
                  placeholder="$ max"
                  size="small"
                  sx={{ width: '130px' }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 15px'
                  }}
                >
                  {options.map((option) => (
                    <Box
                      key={option.label}
                      sx={{
                        padding: '5px',
                        cursor: 'pointer',
                        color: 'blue'
                      }}
                      onClick={() => setMinQuery(option.value)}
                    >
                      {option.label}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 15px'
                  }}
                >
                  {options.map((option) => (
                    <Box
                      key={option.label}
                      sx={{
                        padding: '5px',
                        cursor: 'pointer',
                        color: 'blue'
                      }}
                      onClick={() => setMaxQuery(option.value)}
                    >
                      {option.label}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

            <ActionButtons
              onClear={() => {
                clearParams(filterNameStart, filterNameEnd);
                resetQueryInputs();
                setIsExpanded(false);
              }}
              onSave={saveFiltersParamsToUrl}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MobileVersion;
