import React, { useContext } from 'react';

import styled from 'styled-components';
import Link from 'next/link';
import { TableCell, Typography, Tooltip } from '@mui/material';

import { convertToInternationalCurrencySystem } from '@/utils/convertToInternationalCurrencySystem';
import { UserProfileContext } from '@/contexts/UserProfileContext';
import { SegmentContext } from '@/contexts/SegmentContext';
import { ImageValidView } from '@/components/ImageValidView';

const CustomLink = styled.a`
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;

  & > *:not(img) {
    padding: 16px;
  }

  img {
    margin-left: 16px;
  }
`;

const Columns = ({ dataCompany }) => {
  const { user } = useContext(UserProfileContext);
  const { analytics } = useContext(SegmentContext);

  const goToCompanyProfile = () => {
    if (user?.user_profile) {
      analytics?.track('Companies Table Row Clicked', {
        company: {
          uuid: dataCompany.attributes?.uuid,
          organization_name: dataCompany.attributes?.organization_name
        },
        user: {
          profile_uuid: user.user_profile?.uuid,
          user_id: user.user_profile?.user_id,
          first_name: user.user_profile?.first_name,
          last_name: user.user_profile?.last_name
        }
      });
    }
  };

  const urlImg: string =
    Object.keys(dataCompany).length > 0 &&
    dataCompany.attributes.logo &&
    !/.eps$/.test(dataCompany.attributes.logo)
      ? dataCompany.attributes.logo
      : '/static/images/empty_bg.jpg';

  return (
    <>
      <TableCell sx={{ p: 1, padding: 0 }} onClick={goToCompanyProfile}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <ImageValidView
              path={urlImg}
              defaultPath="/static/images/empty_bg.jpg"
              name={'companie'}
              style={{
                height: '50px',
                width: '50px',
                objectFit: 'contain'
              }}
            />
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell
        className="organization_name"
        onClick={goToCompanyProfile}
        sx={{
          position: 'sticky',
          left: 0,
          padding: 0
        }}
      >
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography
              fontWeight="bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {dataCompany.attributes?.organization_name
                ? dataCompany.attributes.organization_name
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography
              sx={{
                fontSize: 12,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {dataCompany.attributes?.description
                ? dataCompany.attributes.description
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Tooltip title={dataCompany.attributes?.primary_category} arrow>
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: 13,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {dataCompany.attributes?.primary_category
                  ? dataCompany.attributes.primary_category
                  : '-'}
              </Typography>
            </Tooltip>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {dataCompany.attributes?.hq_country
                ? dataCompany.attributes.hq_country
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography
              fontWeight="bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {dataCompany.attributes?.estimated_revenue_range
                ? dataCompany.attributes.estimated_revenue_range
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {dataCompany.attributes?.nr_of_employees
                ? dataCompany.attributes.nr_of_employees
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Typography fontWeight="bold">
              {dataCompany.attributes.total_funding_amount_usd
                ? convertToInternationalCurrencySystem(
                    dataCompany.attributes.total_funding_amount_usd
                  )
                : '-'}
            </Typography>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Tooltip title={dataCompany.attributes?.industries} arrow>
              <Typography
                sx={{
                  fontSize: 12,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {dataCompany.attributes?.industries
                  ? dataCompany.attributes.industries
                  : '-'}
              </Typography>
            </Tooltip>
          </CustomLink>
        </Link>
      </TableCell>

      <TableCell onClick={goToCompanyProfile} sx={{ padding: 0 }}>
        <Link href={`/companies/${dataCompany?.attributes?.uuid}`} passHref>
          <CustomLink>
            <Tooltip title={dataCompany.attributes?.categories} arrow>
              <Typography
                sx={{
                  fontSize: 12,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {dataCompany.attributes?.categories
                  ? dataCompany.attributes.categories
                  : '-'}
              </Typography>
            </Tooltip>
          </CustomLink>
        </Link>
      </TableCell>
    </>
  );
};

export default Columns;
