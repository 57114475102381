import React, { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Button,
  Popover,
  Divider,
  Typography,
  Stack
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useRouterParams } from '@/hooks/useRouterParams';
import ActionButtons from '@/components/Filters/ActionButtons';
import { CurrencyInputField } from '@/components/CurrencyInputField';

interface Props {
  filterId: string;
  filterNameStart: string;
  filterNameEnd: string;
  label: string;
  sx?: object;
  options: any;
}

const WebVersion = ({
  filterId,
  filterNameStart,
  filterNameEnd,
  label,
  options
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [minQuery, setMinQuery] = useState<string | undefined>(undefined);
  const [maxQuery, setMaxQuery] = useState<string | undefined>(undefined);
  const [countCheckedOptions, setCountCheckedOptions] = useState<number>(0);

  const { hasParam, getParamValue, setMultipleParams, clearParams } =
    useRouterParams();

  const saveFiltersParamsToUrl = () => {
    let filters = [];

    if (minQuery) {
      filters.push({
        name: filterNameStart,
        value: minQuery
      });
    }

    if (maxQuery) {
      filters.push({
        name: filterNameEnd,
        value: maxQuery
      });
    }

    if (filters.length) {
      setMultipleParams(filters);
    }

    handleFilterClose();
  };

  const resetQueryInputs = () => {
    setMinQuery(undefined);
    setMaxQuery(undefined);
  };

  const setDefaultRanges = useCallback(() => {
    if (hasParam(filterNameStart)) {
      const minValueFromUrl = getParamValue(filterNameStart);

      if (minValueFromUrl && typeof minValueFromUrl === 'string') {
        setMinQuery(minValueFromUrl);
      }
    }

    if (hasParam(filterNameEnd)) {
      const maxValueFromUrl = getParamValue(filterNameEnd);

      if (maxValueFromUrl && typeof maxValueFromUrl === 'string') {
        setMaxQuery(maxValueFromUrl);
      }
    }
  }, [filterNameStart, filterNameEnd, getParamValue, hasParam]);

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const computeCountCheckedOptions = useCallback(() => {
    setCountCheckedOptions(0);

    if (minQuery) {
      setCountCheckedOptions((countCheckedOptions) => countCheckedOptions + 1);
    }

    if (maxQuery) {
      setCountCheckedOptions((countCheckedOptions) => countCheckedOptions + 1);
    }
  }, [maxQuery, minQuery]);

  useEffect(() => {
    computeCountCheckedOptions();
  }, [computeCountCheckedOptions]);

  useEffect(() => {
    resetQueryInputs();
    setDefaultRanges();
  }, [setDefaultRanges]);

  return (
    <>
      <Button
        data-testid="select-filter-button"
        aria-controls={!!anchorEl ? filterId : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleFilterButtonClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          {!!countCheckedOptions && (
            <Box
              sx={{
                color: '#333',
                background: '#2233541a',
                width: 20,
                height: 20,
                borderRadius: 10
              }}
            >
              {countCheckedOptions}
            </Box>
          )}

          <Typography variant="h5">{label}</Typography>
        </Stack>
      </Button>

      <Popover
        id={filterId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <Box>
              <CurrencyInputField
                value={minQuery}
                onChange={(event) => setMinQuery(event.target.value)}
                placeholder="$ min"
                sx={{ width: '140px' }}
              />
            </Box>
            —
            <Box>
              <CurrencyInputField
                value={maxQuery}
                onChange={(event) => setMaxQuery(event.target.value)}
                placeholder="$ max"
                sx={{ width: '140px' }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              <Box
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 15px'
                }}
              >
                {options.map((option) => (
                  <Box
                    key={option.label}
                    sx={{
                      padding: '5px',
                      cursor: 'pointer',
                      color: 'blue'
                    }}
                    onClick={() => setMinQuery(option.value)}
                  >
                    {option.label}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 15px'
                }}
              >
                {options.map((option) => (
                  <Box
                    key={option.label}
                    sx={{
                      padding: '5px',
                      cursor: 'pointer',
                      color: 'blue'
                    }}
                    onClick={() => setMaxQuery(option.value)}
                  >
                    {option.label}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

          <ActionButtons
            onClear={() => {
              clearParams(filterNameStart, filterNameEnd);
              resetQueryInputs();
              handleFilterClose();
            }}
            onSave={saveFiltersParamsToUrl}
          />
        </Box>
      </Popover>
    </>
  );
};

export default WebVersion;
