import { useState, useMemo } from 'react';

import { useQuery } from 'react-query';
import Router from 'next/router';
import {
  Box,
  Stack,
  Button,
  useMediaQuery,
  useTheme,
  Typography
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import { useRouterParams } from '@/hooks/useRouterParams';
import { useApi } from '@/hooks/useApi';
import SelectFilter from '@/components/Filters/SelectFilter';
import Search from '@/components/Filters/Search';
import RangeFilter from '@/components/Filters/RangeFilter';
import AutocompleteFilter from '@/components/Filters/AutocompleteFilter';
import FiltersSidemodal from './FiltersSidemodal';

const Filters = () => {
  const [showFiltersSidemodal, setShowFiltersSidemodal] =
    useState<boolean>(false);

  const { query } = Router;
  const { clearParams } = useRouterParams();
  const { getFilters } = useApi();
  const { data } = useQuery('getFilters', getFilters);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const convertFilterObjectToArray = (filterObject) => {
    let filterArray = [];

    for (const option in filterObject) {
      filterArray.push({
        id: option,
        name: option,
        occurrences: filterObject[option]
      });
    }

    return filterArray;
  };

  const countActiveFilters = useMemo(() => {
    let count = 0;
    const queryParams = Object.keys(query);

    if (queryParams.length) {
      queryParams.forEach((queryParam) => {
        if (
          !['page', 'per_page', 'q[search]', 'code', 'state'].includes(
            queryParam
          )
        ) {
          if (Array.isArray(query[queryParam])) {
            count = count + query[queryParam].length;
          } else {
            count = count + 1;
          }
        }
      });
    }

    return count;
  }, [query]);

  const hideActiveFilters = () => {
    let hide = true;
    const queryParams = Object.keys(query);

    if (queryParams.length) {
      queryParams.forEach((queryParam) => {
        if (
          !['page', 'per_page', 'q[search]', 'code', 'state'].includes(
            queryParam
          )
        ) {
          hide = false;
        }
      });
    }

    return hide;
  };

  return (
    <Box>
      {data?.industries && (
        <Box mb={1}>
          <Search
            filterId="search-filter"
            filterName="q[search]"
            placeholder="Search company name, description or primary category"
          />

          <Box display="flex">
            {mobile ? (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  mb={1}
                >
                  <Button
                    onClick={() => setShowFiltersSidemodal(true)}
                    variant="outlined"
                    size="small"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: 'center' }}
                    >
                      {!!countActiveFilters && (
                        <Box
                          sx={{
                            color: '#333',
                            background: '#2233541a',
                            width: 20,
                            height: 20,
                            borderRadius: 10
                          }}
                        >
                          {countActiveFilters}
                        </Box>
                      )}

                      <Typography variant="h5">Filters</Typography>
                      <TuneIcon fontSize="small" sx={{ ml: 1 }} />
                    </Stack>
                  </Button>

                  {!hideActiveFilters() && (
                    <Button
                      variant="text"
                      sx={{ ml: 1 }}
                      onClick={() => clearParams()}
                      id="clearAllFilters"
                      size="small"
                    >
                      Clear All
                    </Button>
                  )}
                </Stack>

                <FiltersSidemodal
                  data={data}
                  showModal={showFiltersSidemodal}
                  onHide={() => setShowFiltersSidemodal(false)}
                  countActiveFilters={countActiveFilters}
                />
              </>
            ) : (
              <>
                <AutocompleteFilter
                  filterId="industries-filter"
                  filterName="q[industries_matches_any][]"
                  label="Industries"
                  data={data.industries}
                  sx={{ mr: 1 }}
                />

                <AutocompleteFilter
                  filterId="categories-filter"
                  filterName="q[categories_matches_any][]"
                  label="Categories"
                  data={data.categories}
                  sx={{ mr: 1 }}
                />

                <AutocompleteFilter
                  filterId="tags-filter"
                  filterName="q[tags_matches_any][]"
                  label="Tags"
                  data={data.tags}
                  sx={{ mr: 1 }}
                />

                <RangeFilter
                  filterId="total-funding-type-filter"
                  filterNameStart="q[total_funding_amount_usd_gt]"
                  filterNameEnd="q[total_funding_amount_usd_lt]"
                  label="Total Funding"
                  sx={{ mr: 1 }}
                />

                <SelectFilter
                  filterId="estimated-revenue-range-filter"
                  filterName="q[estimated_revenue_range_matches_any][]"
                  label="Estimated Revenue"
                  data={convertFilterObjectToArray(
                    data.estimated_revenue_range
                  )}
                  sx={{ mr: 1 }}
                />

                <SelectFilter
                  filterId="employees-number-filter"
                  filterName="q[nr_of_employees_matches_any][]"
                  label="Employees"
                  data={convertFilterObjectToArray(data.nr_of_employees)}
                  sx={{ mr: 1 }}
                />

                <SelectFilter
                  filterId="last-funding-type-filter"
                  filterName="q[last_funding_type_matches_any][]"
                  label="Last Funding Type"
                  data={convertFilterObjectToArray(data.last_funding_type)}
                  sx={{ mr: 1 }}
                />

                <SelectFilter
                  filterId="hq-region-filter"
                  filterName="q[hq_region_matches_any][]"
                  label="Region"
                  data={convertFilterObjectToArray(data.hq_region)}
                  sx={{ mr: 1 }}
                />

                <AutocompleteFilter
                  filterId="hq-country-filter"
                  filterName="q[hq_country_cont_any][]"
                  label="Location"
                  data={convertFilterObjectToArray(data.hq_country)}
                  sx={{ mr: 1 }}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
