import { useEffect, useMemo } from 'react';

import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';

import { useRouterParams } from '@/hooks/useRouterParams';
import useDefaultPagination from '@/hooks/useDefaultPagination';
import { useApi } from '@/hooks/useApi';
import Loader from '@/components/Loader';
import PaginationRow from '@/components/Filters/Pagination/PaginationRow';
import TableFundingRounds from './TableFundingRounds';

const FundingRoundsTable = () => {
  const { query } = useRouter();
  const { getFundingRounds } = useApi();
  const { addDefaultQueryParams } = useDefaultPagination();
  const { hasParam } = useRouterParams();
  const { isAuthenticated } = useAuth0();

  const enableApiCall = useMemo(() => {
    return (
      !hasParam('code') &&
      !hasParam('state') &&
      hasParam('page') &&
      hasParam('per_page')
    );
  }, [hasParam]);

  const { data: fundingRounds, isFetching } = useQuery(
    ['getFundingRounds', query],
    () => getFundingRounds(decodeURIComponent(location.search)),
    { enabled: enableApiCall }
  );

  useEffect(() => {
    addDefaultQueryParams();
  }, [query, addDefaultQueryParams]);

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <PaginationRow items={fundingRounds} label="funding rounds" />

          <TableFundingRounds
            fundingRounds={fundingRounds?.data || []}
            totalResults={fundingRounds?.meta?.total_items || 0}
          />

          {isAuthenticated && (
            <PaginationRow items={fundingRounds} label="funding rounds" />
          )}
        </>
      )}
    </>
  );
};

export default FundingRoundsTable;
