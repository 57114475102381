import { TableRow } from '@mui/material';

import { Company } from '@/models/company';
import ColumnsBlurred from './ColumnsBlurred';

interface Props {
  dataCompany?: Company;
}

const RowBlurred = ({ dataCompany }: Props) => {
  return (
    <TableRow
      key={dataCompany.id}
      sx={{
        backgroundColor: '#626162'
      }}
    >
      <ColumnsBlurred dataCompany={dataCompany} />
    </TableRow>
  );
};

export default RowBlurred;
