import styled from 'styled-components';
import { TableCell, Typography } from '@mui/material';

import { convertToInternationalCurrencySystem } from '@/utils/convertToInternationalCurrencySystem';
import { ImageValidView } from '@/components/ImageValidView';

const CustomLink = styled.a`
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;

  & > *:not(img) {
    padding: 16px;
  }

  img {
    margin-left: 16px;
  }
`;

const ColumnsBlurred = ({ dataCompany }) => {
  const urlImg: string =
    Object.keys(dataCompany).length > 0 &&
    dataCompany.attributes.logo &&
    !/.eps$/.test(dataCompany.attributes.logo)
      ? dataCompany.attributes.logo
      : '/static/images/empty_bg.jpg';

  return (
    <>
      <TableCell sx={{ p: 1, padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <ImageValidView
            path={urlImg}
            defaultPath="/static/images/empty_bg.jpg"
            name={'companie'}
            style={{
              height: '50px',
              width: '50px',
              objectFit: 'contain'
            }}
          />
        </CustomLink>
      </TableCell>

      <TableCell
        sx={{
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }}
      >
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.organization_name
              ? dataCompany.attributes.organization_name
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            sx={{
              fontSize: 12,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.description
              ? dataCompany.attributes.description
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              fontSize: 13,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.primary_category
              ? dataCompany.attributes.primary_category
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.hq_country
              ? dataCompany.attributes.hq_country
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.estimated_revenue_range
              ? dataCompany.attributes.estimated_revenue_range
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.nr_of_employees
              ? dataCompany.attributes.nr_of_employees
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography fontWeight="bold">
            {dataCompany.attributes.total_funding_amount_usd
              ? convertToInternationalCurrencySystem(
                  dataCompany.attributes.total_funding_amount_usd
                )
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            sx={{
              fontSize: 12,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.industries
              ? dataCompany.attributes.industries
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>

      <TableCell sx={{ padding: 0, filter: 'blur(5px)', borderWidth: 0 }}>
        <CustomLink>
          <Typography
            sx={{
              fontSize: 12,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {dataCompany.attributes?.categories
              ? dataCompany.attributes.categories
              : '-'}
          </Typography>
        </CustomLink>
      </TableCell>
    </>
  );
};

export default ColumnsBlurred;
