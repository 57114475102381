import React, { ReactNode } from 'react';

import { TableCell, Typography } from '@mui/material';

import { Acquisition } from 'src/models/Acquisition';
import { CustomLink } from './CustomLink';

class AcquisitionTypeCell {
  public static GetCellBody(
    acquisition: Acquisition,
    isBlurred: boolean = false
  ): ReactNode {
    const tableCellHtmlAttributes = isBlurred
      ? { padding: 0, filter: 'blur(5px)', borderWidth: 0 }
      : { padding: 0 };

    return (
      <TableCell onClick={() => {}} sx={tableCellHtmlAttributes}>
        <CustomLink>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {acquisition.attributes?.acquisition_type || '-'}
          </Typography>
        </CustomLink>
      </TableCell>
    );
  }

  public static GetCellHeader(): ReactNode {
    return (
      <TableCell align="left" width="11%">
        <Typography fontWeight="bold">Acquisition Type</Typography>
      </TableCell>
    );
  }
}

export default AcquisitionTypeCell;
