import { TableRow } from '@mui/material';

import { FundingRound } from '@/models/FundingRound';
import ColumnsBlurred from './ColumnsBlurred';

interface Props {
  dataFundingRound?: FundingRound;
}

const RowBlurred = ({ dataFundingRound }: Props) => {
  return (
    <TableRow
      key={dataFundingRound.id}
      sx={{
        backgroundColor: '#626162'
      }}
    >
      <ColumnsBlurred fundingRound={dataFundingRound} />
    </TableRow>
  );
};

RowBlurred.defaultProps = {
  dataFundingRound: {} as FundingRound
};

export default RowBlurred;
