import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { Acquisition } from '@/models/Acquisition';
import Row from './Row';
import AcquisitionTypeCell from './Cells/AcquisitionTypeCell';
import AcquisitionTransactionNameCell from './Cells/AcquisitionTransactionNameCell';
import AcquisitionTermsCell from './Cells/AcquisitionTermsCell';
import AcquisitionPriceUSDCell from './Cells/AcquisitionPriceUSDCell';
import AcquisitionAnnouncedDateCell from './Cells/AcquisitionAnnouncedDateCell';
import AcquisitionAcquirerNameCell from './Cells/AcquisitionAcquirerNameCell';
import AcquisitionAcquireeNameCell from './Cells/AcquisitionAcquireeNameCell';
import UpsellBox from '@/content/CompaniesTab/Table/UpsellBox';

const TableLoader = () => {
  return (
    <TableRow>
      <TableCell sx={{ height: '58px', padding: 0 }}>
        <Skeleton
          animation="wave"
          sx={{
            borderRadius: 0,
            width: '100%',
            height: '100%',
            transform: 'none',
            background: 'none'
          }}
        />
      </TableCell>
    </TableRow>
  );
};

interface Props {
  acquisitions: Acquisition[];
  totalResults: number;
}

const TableAcquisitions = ({ acquisitions, totalResults }: Props) => {
  const { isAuthenticated } = useAuth0();
  if (!acquisitions.length) {
    return (
      <Typography
        sx={{ py: 10 }}
        variant="h3"
        fontWeight="normal"
        color="text.secondary"
        align="center"
      >
        We couldn't find any results matching your search criteria
      </Typography>
    );
  }

  return (
    <TableContainer id="tableAcquisitions">
      <Table>
        <TableHead>
          {acquisitions.length === 0 ? (
            <TableLoader />
          ) : (
            <TableRow>
              {AcquisitionTransactionNameCell.GetCellHeader()}
              {AcquisitionAcquireeNameCell.GetCellHeader()}
              {AcquisitionAcquirerNameCell.GetCellHeader()}
              {AcquisitionAnnouncedDateCell.GetCellHeader()}
              {AcquisitionPriceUSDCell.GetCellHeader()}
              {AcquisitionTypeCell.GetCellHeader()}
              {AcquisitionTermsCell.GetCellHeader()}
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {acquisitions.length === 0 ? (
            <>
              <Row />
              <Row />
              <Row />
              <Row />
              <Row />
              <Row />
              <Row />
              <Row />
            </>
          ) : isAuthenticated ? (
            acquisitions.map((acquisition) => (
              <Row key={acquisition.id} dataAcquisition={acquisition} />
            ))
          ) : (
            <>
              {acquisitions.slice(0, 5).map((acquisition) => (
                <Row key={acquisition.id} dataAcquisition={acquisition} />
              ))}

              {acquisitions.length > 5 &&
                acquisitions
                  .slice(5, 8)
                  .map((acquisition) => (
                    <Row
                      key={acquisition.id}
                      dataAcquisition={acquisition}
                      isRowBlurred={true}
                    />
                  ))}
            </>
          )}
        </TableBody>
      </Table>
      {!isAuthenticated && totalResults > 7 && (
        <UpsellBox resultsCount={totalResults} />
      )}
    </TableContainer>
  );
};

export default TableAcquisitions;
