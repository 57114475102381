import { useState, useMemo } from 'react';

import { useQuery } from 'react-query';
import Router from 'next/router';
import {
  Box,
  Stack,
  Button,
  useMediaQuery,
  useTheme,
  Typography
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import { useRouterParams } from '@/hooks/useRouterParams';
import { useApi } from '@/hooks/useApi';
import Search from '@/components/Filters/Search';
import RangeFilter from '@/components/Filters/RangeFilter';
import AutocompleteFilter from '@/components/Filters/AutocompleteFilter';
import FiltersSidemodal from './FiltersSidemodal';

const Filters = () => {
  const [showFiltersSidemodal, setShowFiltersSidemodal] =
    useState<boolean>(false);

  const { query } = Router;
  const { clearParams } = useRouterParams();
  const { getAcquisitionsFilters } = useApi();
  const { data } = useQuery('getAcquisitionsFilters', getAcquisitionsFilters);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const convertFilterObjectToArray = (filterObject) => {
    let filterArray = [];

    for (const option in filterObject) {
      filterArray.push({
        id: option,
        name: option,
        occurrences: filterObject[option]
      });
    }

    return filterArray;
  };

  const countActiveFilters = useMemo(() => {
    let count = 0;
    const queryParams = Object.keys(query);

    if (queryParams.length) {
      queryParams.forEach((queryParam) => {
        if (
          ![
            'page',
            'per_page',
            'q[acquiree_name_or_acquirer_name_or_transaction_name_cont]',
            'code',
            'state'
          ].includes(queryParam)
        ) {
          if (Array.isArray(query[queryParam])) {
            count = count + query[queryParam].length;
          } else {
            count = count + 1;
          }
        }
      });
    }

    return count;
  }, [query]);

  const hideActiveFilters = () => {
    let hide = true;
    const queryParams = Object.keys(query);

    if (queryParams.length) {
      queryParams.forEach((queryParam) => {
        if (
          ![
            'page',
            'per_page',
            'q[acquiree_name_or_acquirer_name_or_transaction_name_cont]',
            'code',
            'state'
          ].includes(queryParam)
        ) {
          hide = false;
        }
      });
    }

    return hide;
  };

  return (
    <Box>
      {data && (
        <Box mb={1}>
          <Search
            filterId="search-filter"
            filterName="q[acquiree_name_or_acquirer_name_or_transaction_name_cont]"
            placeholder="Search company or transaction name"
          />

          <Box display="flex">
            {mobile ? (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  mb={1}
                >
                  <Button
                    onClick={() => setShowFiltersSidemodal(true)}
                    variant="outlined"
                    size="small"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: 'center' }}
                    >
                      {!!countActiveFilters && (
                        <Box
                          sx={{
                            color: '#333',
                            background: '#2233541a',
                            width: 20,
                            height: 20,
                            borderRadius: 10
                          }}
                        >
                          {countActiveFilters}
                        </Box>
                      )}

                      <Typography variant="h5">Filters</Typography>
                      <TuneIcon fontSize="small" sx={{ ml: 1 }} />
                    </Stack>
                  </Button>

                  {!hideActiveFilters() && (
                    <Button
                      variant="text"
                      sx={{ ml: 1 }}
                      onClick={() => clearParams()}
                      id="clearAllFilters"
                      size="small"
                    >
                      Clear All
                    </Button>
                  )}
                </Stack>

                <FiltersSidemodal
                  data={data}
                  showModal={showFiltersSidemodal}
                  onHide={() => setShowFiltersSidemodal(false)}
                  countActiveFilters={countActiveFilters}
                />
              </>
            ) : (
              <>
                <AutocompleteFilter
                  filterId="acquisition_type-filter"
                  filterName="q[acquisition_type_matches_any][]"
                  label="Acquisition type"
                  data={convertFilterObjectToArray(data.acquisition_types)}
                  sx={{ mr: 1 }}
                />

                <AutocompleteFilter
                  filterId="acquisition_terms-filter"
                  filterName="q[acquisition_terms_matches_any][]"
                  label="Acquisition terms"
                  data={convertFilterObjectToArray(data.acquisition_terms)}
                  sx={{ mr: 1 }}
                />

                <RangeFilter
                  filterId="price-filter"
                  filterNameStart="q[price_usd_gt]"
                  filterNameEnd="q[price_usd_lt]"
                  label="Price"
                  sx={{ mr: 1 }}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
