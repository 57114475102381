import React from 'react';

import { useQuery } from 'react-query';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { useApi } from '@/hooks/useApi';
import AcquisitionsTable from '@/content/Acquisitions/Table';
import ActiveFilters from '@/components/Filters/ActiveFilters';
import { acquisitionsFiltersMapping } from './constants';
import Filters from './Filters';

const Acquisitions = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getAcquisitionsFilters } = useApi();
  const { data } = useQuery('getAcquisitionsFilters', getAcquisitionsFilters);

  return (
    <Box>
      <Filters />

      {!mobile && (
        <ActiveFilters
          filtersOptions={data}
          filtersMapping={acquisitionsFiltersMapping}
        />
      )}

      <AcquisitionsTable />
    </Box>
  );
};

export default Acquisitions;
