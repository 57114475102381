import React from 'react';

import { useQuery } from 'react-query';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { useApi } from '@/hooks/useApi';
import { filtersMapping } from '@/content/CompaniesTab/constants';
import CompaniesTable from '@/content/CompaniesTab/Table';
import ActiveFilters from '@/components/Filters/ActiveFilters';
import Filters from './Filters';

const CompaniesTab = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getFilters } = useApi();
  const { data } = useQuery('getFilters', getFilters);

  return (
    <Box>
      <Filters />
      {!mobile && (
        <ActiveFilters filtersOptions={data} filtersMapping={filtersMapping} />
      )}
      <CompaniesTable />
    </Box>
  );
};

export default CompaniesTab;
