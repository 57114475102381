import { TableRow } from '@mui/material';

import { FundingRound } from '@/models/FundingRound';
import Columns from './Columns';

interface Props {
  dataFundingRound?: FundingRound;
}

const Row = ({ dataFundingRound }: Props) => {
  return (
    <TableRow
      key={dataFundingRound.id}
      sx={{
        backgroundColor: 'white',
        '&>.organization_name': {
          backgroundColor: 'white'
        },
        '&:hover': {
          backgroundColor: '#FBFBFC',
          '&>.organization_name': {
            backgroundColor: '#FBFBFC'
          }
        }
      }}
    >
      <Columns fundingRound={dataFundingRound} />
    </TableRow>
  );
};

Row.defaultProps = {
  dataFundingRound: {} as FundingRound
};

export default Row;
