import React, { ReactNode } from 'react';

import { TableCell, Typography } from '@mui/material';

import { Acquisition } from 'src/models/Acquisition';
import { CustomLink } from './CustomLink';

class AcquisitionTransactionNameCell {
  public static GetCellBody(
    acquisition: Acquisition,
    isBlurred: boolean = false
  ): ReactNode {
    const tableCellHtmlAttributes = isBlurred
      ? {
          position: 'sticky',
          left: 0,
          padding: 0,
          filter: 'blur(5px)',
          borderWidth: 0
        }
      : { position: 'sticky', left: 0, padding: 0 };

    return (
      <TableCell
        className="organization_name"
        onClick={() => {}}
        sx={tableCellHtmlAttributes}
      >
        <CustomLink>
          <Typography
            fontWeight="bold"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {acquisition.attributes?.transaction_name || '-'}
          </Typography>
        </CustomLink>
      </TableCell>
    );
  }

  public static GetCellHeader(): ReactNode {
    return (
      <TableCell
        align="left"
        width="24%"
        sx={{
          backgroundColor: '#FBFBFC',
          position: 'sticky',
          left: 0
        }}
      >
        <Typography fontWeight="bold">Transaction Name</Typography>
      </TableCell>
    );
  }
}

export default AcquisitionTransactionNameCell;
