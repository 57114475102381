import React from 'react';

import {
  Dialog,
  Button,
  Typography,
  Box,
  IconButton,
  Stack
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';

import RangeFilter from '@/components/Filters/RangeFilter';
import AutocompleteFilter from '@/components/Filters/AutocompleteFilter';

interface Props {
  data: any;
  showModal: boolean;
  onHide: () => void;
  countActiveFilters: number;
}

const FiltersSidemodal = ({
  data,
  showModal,
  onHide,
  countActiveFilters
}: Props) => {
  const convertFilterObjectToArray = (filterObject) => {
    let filterArray = [];

    for (const option in filterObject) {
      filterArray.push({
        id: option,
        name: option,
        occurrences: filterObject[option]
      });
    }

    return filterArray;
  };

  return (
    <Dialog fullScreen open={showModal} onClose={onHide}>
      <Box
        p={2}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <Typography variant="h3">Filters</Typography>

          <IconButton onClick={onHide}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <AutocompleteFilter
          filterId="acquisition_type-filter"
          filterName="q[acquisition_type_matches_any][]"
          label="Acquisition type"
          data={convertFilterObjectToArray(data.acquisition_types)}
          sx={{ mr: 1 }}
        />

        <AutocompleteFilter
          filterId="acquisition_terms-filter"
          filterName="q[acquisition_terms_matches_any][]"
          label="Acquisition terms"
          data={convertFilterObjectToArray(data.acquisition_terms)}
          sx={{ mr: 1 }}
        />

        <RangeFilter
          filterId="price-filter"
          filterNameStart="q[price_usd_gt]"
          filterNameEnd="q[price_usd_lt]"
          label="Price"
          sx={{ mr: 1 }}
        />

        <Button onClick={onHide} variant="contained" sx={{ marginTop: 'auto' }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {!!countActiveFilters && (
              <Box
                sx={{
                  color: '#333',
                  background: 'white',
                  width: 23,
                  height: 23,
                  borderRadius: 8
                }}
              >
                {countActiveFilters}
              </Box>
            )}

            <Typography variant="h5">Apply filters</Typography>
            <TuneIcon fontSize="small" sx={{ ml: 1 }} />
          </Stack>
        </Button>
      </Box>
    </Dialog>
  );
};

export default FiltersSidemodal;
