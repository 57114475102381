import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Button, useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  resultsCount: number;
}

const UpsellBox = ({ resultsCount }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { loginWithRedirect } = useAuth0();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="200px"
      px={5}
      sx={{
        color: 'white',
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0
      }}
    >
      <Typography variant={mobile ? 'h4' : 'h3'} sx={{ my: 3, color: 'white' }}>
        Create a free Supplify account to view all {resultsCount} results.
      </Typography>

      <Button
        variant="contained"
        size={mobile ? 'small' : 'medium'}
        onClick={() => loginWithRedirect()}
      >
        Register
      </Button>
    </Stack>
  );
};

export default UpsellBox;
