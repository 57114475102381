export const filtersMapping = {
  'q[categories_matches_any][]': 'categories',
  'q[industries_matches_any][]': 'industries',
  'q[tags_matches_any][]': 'tags',
  'q[estimated_revenue_range_matches_any][]': 'estimated_revenue_range',
  'q[nr_of_employees_matches_any][]': 'nr_of_employees',
  'q[last_funding_type_matches_any][]': 'last_funding_type',
  'q[hq_region_matches_any][]': 'hq_region',
  'q[hq_country_cont_any][]': 'hq_country',
  'q[total_funding_amount_usd_gt]': 'q[total_funding_amount_usd_gt]',
  'q[total_funding_amount_usd_lt]': 'q[total_funding_amount_usd_lt]',
  'q[location_matches_any][]': 'location',
  'q[industries_cont_any][]': 'industries',
  'q[investment_stage_cont_any][]': 'investment_stage'
};
